import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_daf8JLQYgNg5Oio6i3szlN9gBfCuQpCD32G5_g4EIgQ from "/opt/buildhome/repo/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c from "/opt/buildhome/repo/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import plugin_pctIVG_FA3ygvTkx4z_pYtRdHdDEZ_qPbl4DqvN9CMQ from "/opt/buildhome/repo/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import auth_redirect_HUzDltIkO2fpUO5JHjqMueRAOThb4ANNc2aslGKOTOY from "/opt/buildhome/repo/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import plugin_VW0S5L6gti_L2lJP5Fh8KfD6_3GK52P9spdjsc0v3y4 from "/opt/buildhome/repo/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import route_jkO0byGOUwsdUtLejVDcvR3TxA49bGnQ1du9QMdiZus from "/opt/buildhome/repo/plugins/route.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  supabase_client_daf8JLQYgNg5Oio6i3szlN9gBfCuQpCD32G5_g4EIgQ,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  plugin_pctIVG_FA3ygvTkx4z_pYtRdHdDEZ_qPbl4DqvN9CMQ,
  auth_redirect_HUzDltIkO2fpUO5JHjqMueRAOThb4ANNc2aslGKOTOY,
  plugin_VW0S5L6gti_L2lJP5Fh8KfD6_3GK52P9spdjsc0v3y4,
  route_jkO0byGOUwsdUtLejVDcvR3TxA49bGnQ1du9QMdiZus
]