
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _mu82Eo7FoBQeGJMTKeFzb0ttdNt1MHy1HvEJulSCLDMMeta } from "/opt/buildhome/repo/pages/_.vue?macro=true";
import { default as indexQLbd_45_VEGEymTy61om4OM78JfxLHuFgCIu_qxD7KdRoMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as termsM_RN9_459iuNVtH23UOLrjvoINYU4b2nnq0grhd_RmiYYMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
import { default as privacyArxgULVZ2Zifu3wQfKQ0WvqaXaBxDlpI2F7eal9OET8Meta } from "/opt/buildhome/repo/pages/privacy.vue?macro=true";
import { default as loginDXYgY4qRAkWlQvfAQBu7jVpgDVSTywnpGggy3poR7uEMeta } from "/opt/buildhome/repo/pages/app/login.vue?macro=true";
import { default as logout5vCx9MDPkwzqqFq383RtlhrwgqY_45C761wKMi2MN4fzkMeta } from "/opt/buildhome/repo/pages/app/logout.vue?macro=true";
import { default as confirmVq4m8W7h_JjUk3ZcpbzBKhQm9UN903yOyFgYo3kiO5kMeta } from "/opt/buildhome/repo/pages/app/confirm.vue?macro=true";
import { default as registerBfNx8k1wfWYPAeKxdezS1NgVVJ35eNR4IZQA_454glpgMMeta } from "/opt/buildhome/repo/pages/app/register.vue?macro=true";
import { default as _91id_93x4o7Poj9Blw2Hscf1dazNK1H3CAqxxKeh3KxcHGXP1YMeta } from "/opt/buildhome/repo/pages/app/tags/[id].vue?macro=true";
import { default as onboardingxvKiPZhqKu4J5Uv2_45ZkM6LRmvmnm5yqtrqaPFqqNYb0Meta } from "/opt/buildhome/repo/pages/app/onboarding.vue?macro=true";
import { default as indexFuPgHP5cXXnW9G6QhczRIDYlo_9OyKzF0xDDWKCMlnUMeta } from "/opt/buildhome/repo/pages/app/tags/index.vue?macro=true";
import { default as trashvBZEiWQAR3AVzUFWAUYIhoCnegMAsPgi0Sd2Z8ZFfGIMeta } from "/opt/buildhome/repo/pages/app/tags/trash.vue?macro=true";
import { default as _91id_93IP8mIMwiVrwi6WlAOcO0O6VaCPLHqYQyEOs_45GAMuqK8Meta } from "/opt/buildhome/repo/pages/app/senders/[id].vue?macro=true";
import { default as indexQwCAROrdqqyNFikjqeKo_M0BHQZOihIGcPvcWFpqxmwMeta } from "/opt/buildhome/repo/pages/app/billing/index.vue?macro=true";
import { default as index_454ZRKGLjSHtPkmxrrxnAGj3AZTU7tsYkeP_DdWZOAGgMeta } from "/opt/buildhome/repo/pages/app/profile/index.vue?macro=true";
import { default as index1WQYMwt_cvHQH_45usCHH5pnEnlD5UntnSiO8_45Ve_RW6IMeta } from "/opt/buildhome/repo/pages/app/senders/index.vue?macro=true";
import { default as trashZ7gvi1WdNMs7ryc9NX1p5IUPL_45wVav3DGrQR5wfej6QMeta } from "/opt/buildhome/repo/pages/app/senders/trash.vue?macro=true";
import { default as indexWEILE2HBDKs60NAGONfLheUjeKopFct_imXH6Zq2nqwMeta } from "/opt/buildhome/repo/pages/app/support/index.vue?macro=true";
import { default as _91id_93Oa7nv6XJnrMkJcawqUoimU6g6J_wL0d9Ueqh3_45xKp4EMeta } from "/opt/buildhome/repo/pages/app/documents/[id].vue?macro=true";
import { default as indexca0NbLyXo1qaF2Kd6_45ZEvxsRHxYnlD0XPCFxsMysPJkMeta } from "/opt/buildhome/repo/pages/app/settings/index.vue?macro=true";
import { default as upgradezM_45nIJic8tG8GUM81FPWqscD4kPm1o7tLEXK3PpDzmsMeta } from "/opt/buildhome/repo/pages/app/billing/upgrade.vue?macro=true";
import { default as indexs1ORZ3cmn4MM5YclhdyACwuG2GFggD2DjBtwjYnEiSoMeta } from "/opt/buildhome/repo/pages/app/changelog/index.vue?macro=true";
import { default as indexRzustaqFE_lCT9WSGp6X4L46punzch8O8Ag3pnogs1YMeta } from "/opt/buildhome/repo/pages/app/documents/index.vue?macro=true";
import { default as trashN_uscDCWrqPJIW7M_45DL9GnqPaZOmKZgNsndmNkGhSVAMeta } from "/opt/buildhome/repo/pages/app/documents/trash.vue?macro=true";
import { default as component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "_",
    path: "/_",
    component: () => import("/opt/buildhome/repo/pages/_.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/opt/buildhome/repo/pages/terms.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/opt/buildhome/repo/pages/privacy.vue")
  },
  {
    name: "app-login",
    path: "/app/login",
    meta: loginDXYgY4qRAkWlQvfAQBu7jVpgDVSTywnpGggy3poR7uEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/login.vue")
  },
  {
    name: "app-logout",
    path: "/app/logout",
    component: () => import("/opt/buildhome/repo/pages/app/logout.vue")
  },
  {
    name: "app-confirm",
    path: "/app/confirm",
    component: () => import("/opt/buildhome/repo/pages/app/confirm.vue")
  },
  {
    name: "app-register",
    path: "/app/register",
    meta: registerBfNx8k1wfWYPAeKxdezS1NgVVJ35eNR4IZQA_454glpgMMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/register.vue")
  },
  {
    name: "app-tags-id",
    path: "/app/tags/:id()",
    meta: _91id_93x4o7Poj9Blw2Hscf1dazNK1H3CAqxxKeh3KxcHGXP1YMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/tags/[id].vue")
  },
  {
    name: "app-onboarding",
    path: "/app/onboarding",
    component: () => import("/opt/buildhome/repo/pages/app/onboarding.vue")
  },
  {
    name: "app-tags",
    path: "/app/tags",
    meta: indexFuPgHP5cXXnW9G6QhczRIDYlo_9OyKzF0xDDWKCMlnUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/tags/index.vue")
  },
  {
    name: "app-tags-trash",
    path: "/app/tags/trash",
    meta: trashvBZEiWQAR3AVzUFWAUYIhoCnegMAsPgi0Sd2Z8ZFfGIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/tags/trash.vue")
  },
  {
    name: "app-senders-id",
    path: "/app/senders/:id()",
    meta: _91id_93IP8mIMwiVrwi6WlAOcO0O6VaCPLHqYQyEOs_45GAMuqK8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/app/senders/[id].vue")
  },
  {
    name: "app-billing",
    path: "/app/billing",
    meta: indexQwCAROrdqqyNFikjqeKo_M0BHQZOihIGcPvcWFpqxmwMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/billing/index.vue")
  },
  {
    name: "app-profile",
    path: "/app/profile",
    meta: index_454ZRKGLjSHtPkmxrrxnAGj3AZTU7tsYkeP_DdWZOAGgMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/profile/index.vue")
  },
  {
    name: "app-senders",
    path: "/app/senders",
    meta: index1WQYMwt_cvHQH_45usCHH5pnEnlD5UntnSiO8_45Ve_RW6IMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/senders/index.vue")
  },
  {
    name: "app-senders-trash",
    path: "/app/senders/trash",
    meta: trashZ7gvi1WdNMs7ryc9NX1p5IUPL_45wVav3DGrQR5wfej6QMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/senders/trash.vue")
  },
  {
    name: "app-support",
    path: "/app/support",
    meta: indexWEILE2HBDKs60NAGONfLheUjeKopFct_imXH6Zq2nqwMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/support/index.vue")
  },
  {
    name: "app-documents-id",
    path: "/app/documents/:id()",
    meta: _91id_93Oa7nv6XJnrMkJcawqUoimU6g6J_wL0d9Ueqh3_45xKp4EMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/documents/[id].vue")
  },
  {
    name: "app-settings",
    path: "/app/settings",
    meta: indexca0NbLyXo1qaF2Kd6_45ZEvxsRHxYnlD0XPCFxsMysPJkMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/settings/index.vue")
  },
  {
    name: "app-billing-upgrade",
    path: "/app/billing/upgrade",
    meta: upgradezM_45nIJic8tG8GUM81FPWqscD4kPm1o7tLEXK3PpDzmsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/billing/upgrade.vue")
  },
  {
    name: "app-changelog",
    path: "/app/changelog",
    meta: indexs1ORZ3cmn4MM5YclhdyACwuG2GFggD2DjBtwjYnEiSoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/changelog/index.vue")
  },
  {
    name: "app-documents",
    path: "/app/documents",
    meta: indexRzustaqFE_lCT9WSGp6X4L46punzch8O8Ag3pnogs1YMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/documents/index.vue")
  },
  {
    name: "app-documents-trash",
    path: "/app/documents/trash",
    meta: trashN_uscDCWrqPJIW7M_45DL9GnqPaZOmKZgNsndmNkGhSVAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/documents/trash.vue")
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/old-page",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  }
]